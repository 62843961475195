import React from 'react';
import { lazy } from 'react';

// project imports
import Loadable from 'Component/Loadable';
import MinimalLayout from 'Layout/MinimalLayout';

const AuthLogin = Loadable(lazy(() => import('../Pages/Login')));

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/Login',
      element: <AuthLogin />
    },
   
  ]
};

export default AuthenticationRoutes;

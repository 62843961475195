import PropTypes from 'prop-types';
import React from 'react';

import { useTheme } from '@mui/material/styles';
import { Box, Grid, IconButton } from '@mui/material';
import Images from 'Assets/lazaro-logo.png';


import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import { drawerWidth } from 'Config.js';


import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';


// ==============================|| HEADER ||============================== //

const Header = ({ drawerToggle }) => {
  const theme = useTheme();

  return (
    <>
      <Box width={drawerWidth}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Grid item>
              <Box mt={0.5} >
              <img src={Images} alt="Logo" height="50px" />

              </Box>
            </Grid>
          </Box>
          <Grid item>
            <IconButton
              edge="start"
              sx={{ mr: theme.spacing(1.25) }}
              color="#222222"
             
              aria-label="open drawer"
              onClick={drawerToggle}
              size="large"
              
            >
              <MenuTwoToneIcon sx={{ fontSize: '1.5rem' }} />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <NotificationSection />
      <ProfileSection />
    </>
  );
};

Header.propTypes = {
  drawerToggle: PropTypes.func
};

export default Header;

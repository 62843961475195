import { SecurityServices } from '../EncryptDecrypt/Crypto';
import { SERVER_DOWN_ERROR, UNABLE_TO_PROCESS_REQUEST_ERROR } from './Messages';
import { IsValidString, IsValidStatusCode } from './Validation';
import { RefreshJWTToken as REFRESH_TOKEN, logoutUser as LOGOUT_USER } from '../Api/UsersApi';
import axios from 'axios';
import { API_URL } from '../Api/Config';

const axiosInstance = axios.create({
    timeout: 3*60*60*1000
});

axiosInstance.interceptors.request.use(
    async (config) => {
        config.baseURL = await getBaseUrl();
        return config;
    },
    (error) => Promise.reject(error)
);

const getBaseUrl = async () => {
    const BASE_URL = await API_URL();
    return BASE_URL;
};

export const getKeyData = () => {
    let UserID = sessionStorage.getItem('UserID');
    let RefreshToken = sessionStorage.getItem('RefreshToken');
    let JsonKeyData = {
        UserID: UserID,
        IMEI: 'IMEI',
        Platform: 'WEB',
        RefreshToken: RefreshToken
    };
    let KeyData = JSON.stringify(JsonKeyData);
    KeyData = SecurityServices.encryptFun(KeyData);
    return KeyData;
};

export const GetFormData = (Request) => {
    var form = new FormData();
    form.append('Request', SecurityServices.encryptFun(Request));
    form.append('KeyData', getKeyData());
    return form;
};

export const getAuthorizationHeader = () => {
    return sessionStorage.getItem('SecureToken');
};

export const PostAPI = async (request, url) => {
    let response = GetResponseClass();
    let form = GetFormData(request);

    try {
        const { status, data } = await axiosInstance.post(url, form, {
            headers: {
                Authorization: 'Bearer ' + getAuthorizationHeader()
            }
        });

        return ParseResponse(status, data, response);
    } catch (error) {
        return ParseErrorResponse(error, response);
    }
};

export const PostAPIWithoutRequest = async (url) => {
    let response = GetResponseClass();
    let form = GetKeyData();
    try {
      const { status, data } = await axiosInstance.post(url, form, {
        headers: {
          Authorization: "Bearer " + getAuthorizationHeader(),
        },
      });
  
      return ParseResponse(status, data, response);
    } catch (error) {
      return ParseErrorResponse(error, response);
    }
  };
export const logout = async () => {
    const UserTypeID = sessionStorage.getItem('UserTypeID');

    if (UserTypeID === '6') {
        window.location.href = '/AccessDenied';
        return;
    }
    sessionStorage.setItem('UserID', '0');
    sessionStorage.setItem('Token', '');
    sessionStorage.setItem('Authorize', 'false');
    sessionStorage.clear();
    await LOGOUT_USER();
    window.location.href = '/Login';
};

export const forceLogout = async () => {
    await LOGOUT_USER();
    sessionStorage.setItem('UserID', '0');
    sessionStorage.setItem('Token', '');
    sessionStorage.setItem('Authorize', 'false');
    sessionStorage.clear();
    window.location.href = '/Login';
};

export const GetResponseClass = () => {
    return {
        isAuthorized: false,
        status: false,
        data: UNABLE_TO_PROCESS_REQUEST_ERROR
    };
};

export const ParseResponse = (status, data, response) => {
    if (!IsValidStatusCode(status)) {
        response.data = UNABLE_TO_PROCESS_REQUEST_ERROR;
        return response;
    }

    if (status !== 200) {
        response.data = UNABLE_TO_PROCESS_REQUEST_ERROR + status;
        return response;
    }
    if (status === 401) {
        response.data = UNABLE_TO_PROCESS_REQUEST_ERROR + status;
        response.isAuthorized = false;
        return response;
    }

    if (!IsValidString(data.body)) {
        response.data = UNABLE_TO_PROCESS_REQUEST_ERROR;
        return response;
    }

    let decryptedBody = SecurityServices.decryptFun(data.body);

    if (!IsValidString(decryptedBody)) {
        response.data = UNABLE_TO_PROCESS_REQUEST_ERROR;
        return response;
    }
    response.isAuthorized = true;
    response.status = data.status;
    response.data = decryptedBody;

    return response;
};

const UpdateJWTRefreshToken = async () => {
    let { status, data } = await REFRESH_TOKEN();

    if (!status) logout();

    data = JSON.parse(data);

    if (data.SecureToken === undefined) logout();

    if (data.SecureToken === null) logout();

    sessionStorage.setItem('RefreshToken', data.RefreshToken);
    sessionStorage.setItem('SecureToken', data.SecureToken);
    sessionStorage.setItem('UserID', data.UserID);
    sessionStorage.setItem('FirmName', data.FirmName);
    sessionStorage.setItem('MobileNumber', data.MobileNumber);
    sessionStorage.setItem('UserType', data.UserType);
    sessionStorage.setItem('UserTypeID', data.UserTypeID);
};

export const ParseErrorResponse = (error, returnResponse) => {
    if (error === undefined) {
        logout();
        return;
    }

    if (error.response === undefined) {
        logout();
        return;
    }

    let status = error.response.status;

    if (status === 401) {
        UpdateJWTRefreshToken();
        returnResponse.isAuthorized = true;
        return returnResponse;
    }

    if (error.message === 'Network Error') {
        returnResponse.data = SERVER_DOWN_ERROR;
        return returnResponse;
    }
    returnResponse.isAuthorized = false;
    returnResponse.data = 'Request failed with : ' + error.response.status;
    return returnResponse;
};

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// assets
import 'Assets/scss/style.scss';

import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';

import App from 'Layout/App';
import reducer from 'Store/reducer';


const store = configureStore({ reducer });

const root = createRoot(document.getElementById('root'));


root.render(
  <Provider store={store}>
    <BrowserRouter >
      <App />
      
    </BrowserRouter>
  </Provider>
);


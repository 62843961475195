import React from 'react';
import NavGroup from './NavGroup'; // Import NavGroup component
import menuItem from 'menu-items';

const MenuList = () => {
  let navItems = null;

  // Ensure menuItem exists and it contains an array of items
  if (menuItem && Array.isArray(menuItem.items)) {
    navItems = menuItem.items.map((item) => (
      <NavGroup key={item.id} items={[item]} /> // Pass item wrapped in an array
    ));
  }

  return navItems;
};

export default MenuList;

import * as Icons from '@mui/icons-material';

const icons = {
  NavigationOutlinedIcon: Icons.NavigationOutlined,
  HomeOutlinedIcon: Icons.HomeOutlined,
  ChromeReaderModeOutlinedIcon: Icons.ChromeReaderModeOutlined,
  HelpOutlineOutlinedIcon: Icons.HelpOutlineOutlined,
  SecurityOutlinedIcon: Icons.SecurityOutlined,
  AccountTreeOutlinedIcon: Icons.AccountTreeOutlined,
  BlockOutlinedIcon: Icons.BlockOutlined,
  AppsOutlinedIcon: Icons.AppsOutlined,
  ContactSupportOutlinedIcon: Icons.ContactSupportOutlined,
  TenMpIcon: Icons.TenMp,
  FileUploadIcon: Icons.FileUpload,
  PersonIcon: Icons.Person,
  LogoutIcon: Icons.Logout,
  SummarizeIcon: Icons.Summarize,
  AccountBalanceIcon: Icons.AccountBalance,
  PostAddIcon: Icons.PostAdd,
  NoteAddIcon : Icons.NoteAdd,
  PercentIcon :Icons.Percent
};

const createDynamicMenu = (menuList) => {
  return menuList.map((menuItem) => { 
   
    const hasChildren = menuItem.Children && menuItem.Children.length > 0;

    return {
      id: menuItem.MenuID,
      title: menuItem.Name,
      type: hasChildren ? 'collapse' : 'item',
       icon: icons[menuItem.icon] || null,
      url: menuItem.url || '#', 
      children: hasChildren ? createDynamicMenu(menuItem.Children) : undefined
    };
  });
};

const dynamicMenuList = sessionStorage.getItem('MenuList')
  ? JSON.parse(sessionStorage.getItem('MenuList')).Data
  : [];

const dynamicMenu = createDynamicMenu(dynamicMenuList);

const menuConfig = {
  items: dynamicMenu,
};

export default menuConfig;

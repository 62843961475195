// ==============================|| THEME CONSTANT  ||============================== //
export const VARIANT = 'outlined';
export const gridSpacing = 3;
export const drawerWidth = 280;
export const PRIMARY_COLOR = () => {
    return localStorage.getItem('PrimaryColor') || '#7467ef';
};
// ==============================|| THEME CONFIG  ||============================== //

// Pro Version

import { PostAPI } from '../Helper/Helpers';

export const LoginAPI = async (request) => {
    return PostAPI(request, 'Credential/Login');
};
export const logoutUser = async (request) => {
    return PostAPI(request, 'Credential/Logout');
};

export const RefreshJWTToken = async (request) => {
    return PostAPI(request, 'Credential/RefreshJWTToken');
};
export const SelectProfile = async (request) => {
    return PostAPI(request, 'User/SelectProfile');
};


import PropTypes from 'prop-types';
import React from 'react';


import { List, Typography } from '@mui/material';

import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';

const NavGroup = ({ items }) => {

  if (!items || !Array.isArray(items)) {
    return null; 
  }

  const renderedItems = items.map((menu) => {
    switch (menu.type) {
      case 'collapse':
        return <NavCollapse key={menu.id} menu={menu} level={1} />;
      case 'item':
        return <NavItem key={menu.id} item={menu} level={1} />;
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <List>{renderedItems}</List>;
};

NavGroup.propTypes = {
  items: PropTypes.array.isRequired, 
};

export default NavGroup;

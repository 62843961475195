import React, { lazy } from 'react';

// project import
import MainLayout from 'Layout/MainLayout';
import Loadable from 'Component/Loadable';

const DashboardDefault = Loadable(lazy(() => import('../Pages/Dashboard')));

//const UtilsTypography = Loadable(lazy(() => import('../views/Utils/Typography')));

const ImportFile = Loadable(lazy(() => import('../Pages/ImportFile')));
const PriceUploadMarginSlab = Loadable(lazy(() => import('../Pages/PriceUploadMarginSlab')));

const Home = Loadable(lazy(() => import('../Pages/Home')));
const CodeMapping = Loadable(lazy(() => import('../Pages/CodeMapping')));
const Report = Loadable(lazy(() => import('../Pages/Report')));
const Subscription = Loadable(lazy(() => import('../Pages/Subscription')));
const Profile = Loadable(lazy(() => import('../Pages/Profile')));
const MainMaster = Loadable(lazy(() => import('../Pages/MainMaster')));
const CodeMappingReport = Loadable(lazy(() => import('../Pages/CodeMappingReport'))); 
const CodMapMastrer = Loadable(lazy(() => import('../Pages/CodMapMastrer'))); 
const ShopifyProductMasterMap = Loadable(lazy(() => import('../Pages/ShopifyProductMasterMap'))); 
const Logout = Loadable(lazy(() => import('../Pages/Logout')));
const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/Home',
      element: <Home />
    },
    {
      path: '/home',
      element: <DashboardDefault />
    },

    {
      path: '/dashboard',
      element: <DashboardDefault />
    },
    {
      path: '/CodeMapping',
      element: <CodeMapping />
    },
    { path: '/AddSlab', element: <PriceUploadMarginSlab /> },
    { path: '/ImportFile', element: <ImportFile /> },
    { path: '/Profile', element: <Profile /> },
    { path: '/Report', element: <Report /> },
    { path: '/Subscription', element: <Subscription /> },

    { path: '/CodeMappingReport', element: <CodeMappingReport /> },
    { path: '/Logout', element: <Logout /> },
    { path: '/Mainmaster', element: <MainMaster /> },
    { path: '/Code_Map', element: <CodMapMastrer /> },
    { path: '/ShopifyCode_Map', element: <ShopifyProductMasterMap /> }
  ]
};

export default MainRoutes;

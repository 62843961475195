import React from 'react';
import { useRoutes, useNavigate } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';



export default function ThemeRoutes() {
  const navigate = useNavigate();


  const shouldRedirectToLogin = !sessionStorage.getItem('UserID') ||
    !sessionStorage.getItem('Token') ||
    !sessionStorage.getItem('UserTypeID') ||
    !sessionStorage.getItem('MenuList');


  const routes = useRoutes([MainRoutes, AuthenticationRoutes]);

  React.useEffect(() => {
    if (shouldRedirectToLogin) {
      
      navigate('/Login');
    }
  }, [navigate, shouldRedirectToLogin]);

  return routes;
}
